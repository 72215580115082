import { SignedIn, SignedOut, SignIn, SignInButton, SignUp, useAuth, UserButton } from '@clerk/clerk-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useScreenDimensions from '../hooks/useScreenDimensions';

const Login = () => {
  const nav = useNavigate();
  const location = useLocation();
  const {userId, isLoaded} = useAuth();
  const {height} = useScreenDimensions();

  useEffect(() => {
    if (isLoaded && userId) {
      nav(location.pathname.includes('signup') ? '/set-up' : '/dashboard')
    }

  }, [userId, isLoaded])
  return (
    <div style={{height, display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
    <SignedOut>
        {location.pathname.includes('signup') ? <SignUp signInUrl={`${window.location.origin}/`} forceRedirectUrl={'/set-up'}/> : <SignIn signUpUrl={`${window.location.origin}/signup`}/>}
      </SignedOut>
      <SignedIn>
        <UserButton />
      </SignedIn>
      </div>
  )
};

export default Login;
