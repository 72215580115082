import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { TextField } from '@mui/material';
import { datasource } from '../datasource/datasource';
import { useAuth } from '@clerk/clerk-react';
import { DashboardContext } from '../Pages/dashboard';
import { StandardButton } from './standardButton';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFF8F0',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// export const NewCampaignModalContext = React.createContext<{handleOpen: () => void, handleClose: () => void}>({handleOpen: () => undefined, handleClose: () => undefined});

export const NewCampaginModal = () =>  {
  const {updateCurrentCampaigns} = React.useContext(DashboardContext)
  const {getToken} = useAuth();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [newCampaginName, setNewCampaignName] = React.useState<string>('');

  React.useEffect(() => {
    if (!open) {
      setNewCampaignName('');
    }
  }, [open])

  const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = React.useCallback((e) => {
    setNewCampaignName(e?.target?.value);
  }, [setNewCampaignName]);

  const handleSubmit = React.useCallback(() => {
    const sub = async () => {
      const token = await getToken();
      const campaigns = await datasource.addNewCampaign(newCampaginName, token as string);
      updateCurrentCampaigns(campaigns)
    }
    sub();
    handleClose();
  }, [newCampaginName, handleClose, updateCurrentCampaigns]);

  return (
    // <NewCampaignModalContext.Provider value={{handleOpen, handleClose}}>
      <div style={{marginTop: '2rem'}}>
        <StandardButton onClick={handleOpen} buttonText='Add New Campagin' />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <h2>Add New Campaign</h2>
              <TextField id="outlined-basic" label="New Campaign Name" variant="outlined" value={newCampaginName} onChange={onChange}/>
              <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <StandardButton onClick={handleClose} buttonText='Cancel' />
                <StandardButton onClick={handleSubmit} buttonText='Save New Campagin' />
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    // </NewCampaignModalContext.Provider>
  );
}