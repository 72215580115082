import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './ReviewCard.css'; // Import the CSS file for styling
import { Snackbar } from '@mui/material';
import { DashboardContext } from '../Pages/dashboard';
import { StandardButton } from './standardButton';
import { datasource } from '../datasource/datasource';
import { useAuth } from '@clerk/clerk-react';
import { EditReviewModal } from './editReviewModal';
import { Review } from '../types';


export const ReviewCard = ({ review }: {review: Review}) => {
  const {getToken} = useAuth();
  const aTagRef = useRef<HTMLAnchorElement>(null);
  const placeholderImage = 'https://via.placeholder.com/300x200?text=No+Video+Available';
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openTheModal = useCallback(() => setModalOpen(true), [setModalOpen])

  const {openSnackBar} = useContext(DashboardContext);

  const onCopy = useCallback(() => {
    window.navigator.clipboard.writeText(window.location.origin + '/invite/' + review.hash).then(() => {
      openSnackBar()
    })
  }, [review, openSnackBar]);

  const download = useCallback(async () => {
    const token = await getToken()
    const res = await datasource.getVideo(review.hash, token as string);
    const blob = await res.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    if (aTagRef.current) {
      aTagRef.current.href = blobUrl;
      aTagRef.current.download = `${review.reviewerName}-${review.hash}-SOM.mp4`
      aTagRef.current.click();
      window.URL.revokeObjectURL(blobUrl);
    }
  }, [review, aTagRef, getToken])

  return (
    <>
    <EditReviewModal isOpen={modalOpen} setIsOpen={setModalOpen} review={review}  />
    <div className="card" style={{opacity: modalOpen ? 0 : 1}}>
      <a ref={aTagRef} href='/' style={{display: 'none'}}/>
      {review.videoUrl ? <video src={review.videoUrl} className='card-image' controls/> : <img
        src={placeholderImage}
        alt="Thumbnail"
        className="card-image"
      />}
      <div className="card-content" onClick={openTheModal}>
        <h2 className="card-title">{review.reviewerName}</h2>
        <p className="card-status">Status: {review.status}</p>
        {review.videoUrl ? <StandardButton onClick={download} buttonText='Download Video' /> : null}
        <StandardButton onClick={onCopy} buttonText='Copy Link to Clipboard' />
      </div>
    </div>
    </>
    
  );
};