import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { CheckIcon } from 'lucide-react';
// import Typography from '@mui/material/Typography';

export const RecorderUploadModal = ({open, isMobile, complete}: {open: boolean, isMobile: boolean, complete: boolean}) =>  {
  return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            border: '2px solid #000',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#FFF8F0',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem'
          }}>
            {complete ?
            <>
            <p>Upload Complete. Thank you</p>
            <CheckIcon />
            </>
            :
            <>
            <p>Uploading</p>
            <CircularProgress />
            </>
            
            }
            
          </div>
          </Fade>
        </Modal>
      </div>
  );
}