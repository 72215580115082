import React, { useCallback } from "react";
import './standardButton.css'

export interface StandarButtonProps {
    onClick: (e: any) => void;
    buttonText: string,
    buttonColor?: string,
    customStyles?: React.CSSProperties
}

export const StandardButton = React.memo(({onClick, buttonText, buttonColor, customStyles}: StandarButtonProps) => {
    const style = {...buttonColor ? {backgroundColor: buttonColor} : {}}
    const stopPropOnClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick(e);
    }, [onClick])
    return (
        <button className="standardButton" onClick={stopPropOnClick} style={{...style, ...customStyles}}>
            {buttonText}
        </button>
    )
})