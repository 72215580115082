import useScreenDimensions from "../hooks/useScreenDimensions";
import { NewCampaginModal } from "./newCampaignModal";
import { SideBarItem } from "./sidebarItem";

export interface SideBarItemData {
    name: string,
    isActive: boolean,
    id: number
}

export interface SideBarProps {
    sidebarItems: SideBarItemData[],
    setActiveItem: (selectedSidebarItem: SideBarItemData) => void
}

export const SideBar = (props: SideBarProps) => {
    const {sidebarItems, setActiveItem} = props;

    // const {height} = useScreenDimensions();

    return (
        <div className="sideBar">
            <div className="sidebarHeader">
                <h2>My Campaigns</h2>
            </div>
            <br/>
            {sidebarItems.map(el => {
                return (
                    <SideBarItem sidebarItem={el} setActiveSidebarItem={setActiveItem} key={el.id}/>
                )
            })}
            <NewCampaginModal />
        </div>
    )
}