import { useCallback, useMemo } from "react";
import { SideBarItemData } from "./sidbar";

export interface SideBarItemProps {
    sidebarItem: SideBarItemData
    setActiveSidebarItem: (sidebarItem: SideBarItemData) => void;
}

export const SideBarItem = (props: SideBarItemProps) => {

    const {sidebarItem: {name, isActive}, setActiveSidebarItem} = props;

    const onClick = useCallback(() => {
        setActiveSidebarItem(props.sidebarItem);
    }, [setActiveSidebarItem, props.sidebarItem])

    const classes = useMemo(() => {
        let classes = 'sideBarItemContainer';
        if (isActive) {
            classes += ' activeSidebarItem'
        }
        return classes
    }, [isActive])
    
    return (
        <div className={classes} onClick={onClick}>
            <h4>{name}</h4>
        </div>
    )
}