import { UserButton } from "@clerk/clerk-react"
import { NewReviewModal } from "./newReviewModal"
import { StandardButton } from "./standardButton"
import { useCallback, useContext } from "react"
import { DashboardContext } from "../Pages/dashboard"
import { useLocation } from "react-router-dom"

export const DashBoardTopBar = () => {

    const {activeCampaign, openSnackBar} = useContext(DashboardContext);

    const onButtonPress = () => {
        window.navigator.clipboard.writeText(window.location.origin + '/org/' + activeCampaign?.hash)
        openSnackBar();
    }

    return (
        <div className="dashboardTopBar">
            <div className="emptySpaceInTopBar">

            </div>
            <div className="spaceBetween">
                {activeCampaign ? 
                <>
                <NewReviewModal />
                <StandardButton onClick={onButtonPress} buttonText="Copy Campaign Review Link" />
                </> : null}
                <UserButton />
            </div>
        </div>
    )
}