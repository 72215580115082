import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import Webcam from "react-webcam";
import { RecorderInfoResponse } from "../types";
import { datasource } from "../datasource/datasource";
import { isMobile } from "react-device-detect";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { StandardButton } from "../components/standardButton";
import './recorder.css';
import { RecorderUploadModal } from "../components/recorderUploadModal";

export interface RecorderPageParams {
  id: string;
}

const mediaSettings = {
  facingMode: "user",
  width: { min: 640, ideal: 1280, max: 1920 },
  height: { min: 480, ideal: 720, max: 1080 },
};

export const Recorder = () => {
  // @ts-ignore
  const param: RecorderPageParams = useParams();
  const location = useLocation();
  const isInvite = useMemo(() => {
    return location.pathname.includes('invite');
  }, [location]);
  const { height, width } = useScreenDimensions();
  const videoInputRef = useRef<HTMLInputElement | null>(null);

  const [reviewerInfo, setReviewerInfo] = useState<RecorderInfoResponse>();
  const [uploadStarted, setUploadStarted] = useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);


  useEffect(() => {
    const getVal = async () => {
      const val = await datasource.getRecordData(param.id, isInvite);
      setReviewerInfo(val);
    };
    getVal();
  }, [param]);

  const videoPlaybackRef = useRef<HTMLVideoElement | null>(null);
  const webcamRef = React.useRef<any | null>(null);
  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [watchVideo, setWatchVideo] = useState<boolean>(false);

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/mp4",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }: any) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    // @ts-ignore
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  // const handleDownload = React.useCallback(() => {
  //   if (recordedChunks.length) {
  //     const blob = new Blob(recordedChunks, {
  //       type: "video/mp4",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     document.body.appendChild(a);
  //     // @ts-ignore
  //     a.style = "display: none";
  //     a.href = url;
  //     a.download = "react-webcam-stream-capture.mp4";
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     setRecordedChunks([]);
  //   }
  // }, [recordedChunks]);

  const submitVideo = React.useCallback(async () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      setUploadStarted(true)
      const res = await datasource.submitVideo(param.id, blob, isInvite);
      if (res.ok) {
        setUploadComplete(true);
      }
    }
  }, [recordedChunks, param, isInvite]);

  const reviewQuestions = useMemo(() => {
    if (reviewerInfo?.customPrompt) {
      return (
        <div>
          <p>
            Just relax and be honest. {reviewerInfo.orgName} would specifically
            like if you answered the following question:{" "}
            {reviewerInfo.customPrompt}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Just relax and be honest. How has your experience been with{" "}
            {reviewerInfo?.orgName}?
          </p>
        </div>
      );
    }
  }, [reviewerInfo]);

  const handleWatchVideoPress = useCallback(() => {
    setWatchVideo(true);
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      const url = URL.createObjectURL(blob);

      setTimeout(() => {
        if (videoPlaybackRef.current) {
          videoPlaybackRef.current.src = url;
        }
      }, 0);
    }
  }, [setWatchVideo, recordedChunks, videoPlaybackRef]);

  const handleRerecordVideoPress = useCallback(() => {
    setWatchVideo(false);
  }, [setWatchVideo]);

  const renderMobileLayout = useMemo(
    () => isMobile || width <= 768,
    [isMobile, width]
  );

  const handleUploadButtonPress = useCallback(() => {
    videoInputRef.current?.click();
  }, [videoInputRef.current]);

  const handleUploadChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const arrayBuffer = await e.target.files?.[0]?.arrayBuffer()
      const blob = new Blob([arrayBuffer], { type: 'video/mp4' });
      // @ts-ignore
      setRecordedChunks([blob])
    }
  }, [])

  return (
    <>
      <input type="file" id="videoInput" accept="video/mp4" capture="user" style={{display: 'none'}} ref={videoInputRef} onChange={handleUploadChange}></input>
      <RecorderUploadModal open={uploadStarted} isMobile={renderMobileLayout} complete={uploadComplete}/>
      {renderMobileLayout ? (
        <div style={{width: width, height: height, display: 'flex', justifyContent: 'space-between', flexFlow: 'column'
        }}>
          <div className="spaceReviewPage">
            <h2>{reviewerInfo?.orgName} has invited you to leave a review</h2>
            <p>Just relax and be honest. What has been your experience with {reviewerInfo?.orgName}</p>
          </div>
          <div className="spaceReviewPage">
            {recordedChunks.length ? 
              <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
              <StandardButton onClick={handleUploadButtonPress} buttonText="Re-Record" customStyles={{height: '5rem', fontSize: '3rem'}}/>
              <StandardButton onClick={submitVideo} buttonText="Submit Recording" customStyles={{height: '5rem', fontSize: '3rem'}}/>
              </div>
             :
              <StandardButton onClick={handleUploadButtonPress} buttonText="Record" customStyles={{height: '5rem', fontSize: '3rem'}}/>
            }
          </div>
        </div>
      ) : (
        <>
          <h1>Welcome {reviewerInfo?.reviewerName}</h1>
          <h3>Thank you for reviewing {reviewerInfo?.orgName}</h3>
          <div>{reviewQuestions}</div>

          {!watchVideo ? (
            <>
              <Webcam
                audio={true}
                muted={true}
                ref={webcamRef}
                videoConstraints={mediaSettings}
              />
              {capturing ? (
                <StandardButton onClick={handleStopCaptureClick} buttonText="Stop Recording" />
              ) : (
                <StandardButton onClick={handleStartCaptureClick}  buttonText="Start Capture"/>
              )}{" "}
            </>
          ) : (
            <>
              <video ref={videoPlaybackRef} controls={true} />
              <StandardButton onClick={handleRerecordVideoPress} buttonText="Re-Record Review" />
            </>
          )}

          {recordedChunks.length > 0 && (
            <>
              <StandardButton onClick={submitVideo} buttonText="Submit Video"/>

              {!watchVideo && (
                <StandardButton onClick={handleWatchVideoPress} buttonText="Watch Video"/>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
