import { useMemo } from "react";
import { Review } from "../types";
import { ReviewCard } from "./reviewCard";
import EmptyCampaign from '../assets/emptyCampaign.svg'

export interface DashboardContentSectionProps {
    reviews: Review[]
}

export const DashboardContentSection = ({reviews}: DashboardContentSectionProps) => {
    const reviewCards = useMemo(() => {
        return reviews.map(el => <ReviewCard review={el} key={el.id}/>)
    }, [reviews]);

    const staticContent = useMemo(() => {
        return (
            <div className="emptyCampaignGraphicContainer">
                <div className="centerAlginColumn">
                    <img src={EmptyCampaign} style={{height: '30rem', width: '30rem', overflow: 'hidden'}}/>
                    <p>No reviews added</p>
                </div>
            </div>
        )
    },[])
    return (
        <div className="reviewContainer">
            {reviews.length ? reviewCards : staticContent}
        </div>
    )
}